import breakpoint from "../Breakpoint";

function isMobileView() {
    return breakpoint() === 'xs' || breakpoint() === 'sm' || breakpoint() === 'md' || breakpoint() === 'lg';
}

document.addEventListener('DOMContentLoaded', () => {
    const Selectors = {
        VIDEO_IFRAME: '#jsVideoCollectionIframe',
        VIDEO_TITLE: '.jsVideoCollectionTitle',
        VIDEO_SUBTITLE: '.jsVideoCollectionSubTitle',
        VIDEO_TEXT: '.jsVideoCollectionText',
        VIDEO_ITEM: '.jsVideoCollectionItem',
        DOCTORS_SECTION: '.meet-your-doctors .doctors',
    };

    const DataAttributes = {
        DATA_VIDEO_LINK: 'data-video-link',
        DATA_TITLE: 'data-title',
        DATA_SUBTITLE: 'data-subtitle',
        DATA_TEXT: 'data-text-html',
    };

    const videoIframe = document.querySelector(Selectors.VIDEO_IFRAME);
    const videoTitleDiv = document.querySelector(Selectors.VIDEO_TITLE);
    const videoSubtitleDiv = document.querySelector(Selectors.VIDEO_SUBTITLE);
    const videoTextDiv = document.querySelector(Selectors.VIDEO_TEXT);
    const videoItems = Array.from(document.querySelectorAll(Selectors.VIDEO_ITEM));
    const doctorsSection = document.querySelector(Selectors.DOCTORS_SECTION);

    let currentlyPlayingItem;
    if (doctorsSection) {
        currentlyPlayingItem = doctorsSection.querySelector('.selected-video');
        if (!currentlyPlayingItem) {
            currentlyPlayingItem = document.createElement('div'); 
            currentlyPlayingItem.classList.add('doctors-item', 'selected-video');
            currentlyPlayingItem.textContent = 'Currently Playing: ';
            doctorsSection.insertBefore(currentlyPlayingItem, doctorsSection.firstChild);
        }
    }

    function playFirstVideo() {
        if (videoItems && videoItems.length > 0) {
            const firstVideo = videoItems[0];
            const videoLink = firstVideo.getAttribute(DataAttributes.DATA_VIDEO_LINK);
            const newTitle = firstVideo.getAttribute(DataAttributes.DATA_TITLE);
            const newSubTitle = firstVideo.getAttribute(DataAttributes.DATA_SUBTITLE);
            const newText = firstVideo.getAttribute(DataAttributes.DATA_TEXT);

            if (videoIframe) videoIframe.src = videoLink;
            if (videoTitleDiv) videoTitleDiv.innerHTML = newTitle;
            if (videoSubtitleDiv) videoSubtitleDiv.innerHTML = newSubTitle;
            if (videoTextDiv) videoTextDiv.innerHTML = newText;

            if (currentlyPlayingItem) currentlyPlayingItem.textContent = `Currently Playing: ${newTitle}`;
        }
    }

    playFirstVideo();

    if (videoItems && videoItems.length > 0) {
        for (let i = 0; i < videoItems.length; i++) {
            videoItems[i].addEventListener('click', onVideoItemClick);
        }
    }

    function onVideoItemClick(event) {

        event.preventDefault();

        const videoLink = this.getAttribute(DataAttributes.DATA_VIDEO_LINK);
        const newTitle = this.getAttribute(DataAttributes.DATA_TITLE);
        const newSubTitle = this.getAttribute(DataAttributes.DATA_SUBTITLE);
        const newText = this.getAttribute(DataAttributes.DATA_TEXT);

        if (videoIframe) videoIframe.src = videoLink;
        if (videoTitleDiv) videoTitleDiv.innerHTML = newTitle;
        if (videoSubtitleDiv) videoSubtitleDiv.innerHTML = newSubTitle;
        if (videoTextDiv) videoTextDiv.innerHTML = newText;


        if (currentlyPlayingItem) currentlyPlayingItem.textContent = `Currently Playing: ${newTitle}`;
    }


    const doctorsTitle = document.querySelector('.meet-your-doctors .title');

    if (doctorsSection && doctorsTitle) {
        if (!isMobileView()) {
            doctorsSection.style.display = 'block';

        } else {
            doctorsSection.style.display = 'none'; 
            doctorsTitle.addEventListener('click', () => {
                const isExpanded = doctorsSection.style.display === 'block';
                doctorsSection.style.display = isExpanded ? 'none' : 'block';

                doctorsTitle.classList.toggle('active', !isExpanded);
            });
        }
    }
});
